import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as accountService from "../../../api/services/Account";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import {
  AccountById,
  CreateAccount,
} from "../../../api/services/Account/account.types";
import CreateOrEditAccount from "./createOrEdit";

export default function AccountEdit() {
  const { id }: any = useParams();
  const history = useHistory();
  //#region Formulario
  const [initialValuesForm, setInitialValuesForm] = useState<AccountById>();
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
    company: Yup.string().required("Campo Requerido"),
    email: Yup.string().email("Email Inválido ").required("Campo Requerido"),
    accountType: Yup.number().required("Campo Requerido"),
    accountantName: Yup.string().nullable(),
    accountingStudy: Yup.string().nullable(),
    accountantPhone: Yup.string()
      .trim()
      .matches(
        /^[0-9]*$/g,
        "El teléfono debe contener sólo números y sin espacios"
      )
      .nullable(),
    accountantContact: Yup.string().nullable(),
    accountantEmail: Yup.string()
      .matches(
        /^\s*([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})(\s*;\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})*\s*$/,
        "Ingrese uno o más correos válidos separados por ;"
      )
      .nullable(),
    additionalData: Yup.string().nullable(),
    whitoutAFIPCertificate: Yup.boolean(),
    dolarValue: Yup.string().when("accountType", {
      is: 2,
      then: Yup.string().required("Campo Requerido"),
      otherwise: Yup.string().nullable(),
    }),
    accountPlanId: Yup.number().required("Campo Requerido"),
    certificateAFIP: Yup.boolean(),
    certificateList: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          changeCertificate: Yup.boolean(),
          certificate: Yup.mixed().when("whitoutAFIPCertificate", {
            is: false,
            then: Yup.mixed()
              .nullable()
              .test("es-archivo", "Debe ser un archivo", (value) => {
                return value instanceof File;
              }),
            otherwise: Yup.mixed().nullable(),
          }),
          contentLenght: Yup.number(),
          cUIT: Yup.string(),
          apiKey: Yup.string(),
          certificateExpiredDay: Yup.string().nullable(),
          deleted: Yup.boolean(),
          afipSalePoint: Yup.array().of(Yup.string()),
          businessName: Yup.string(),
        })
      )
      .nullable(),
  });
  useEffect(() => {
    setIsLoading(true);
    if (id != null) {
      getAccount(id);
    }
  }, []);

  const getAccount = (id: number) => {
    setIsLoading(true);
    accountService
      .getAccountById(id)
      .then(({ data }) => {
        if (data) {
          setInitialValuesForm({
            name: data.name,
            accountType: data.accountTypeId,
            accountTypeName: data.accountTypeName,
            whitoutAFIPCertificate: data.whitoutAFIPCertificate,
            company: data.company,
            dateCreateOn: data.dateCreateOn,
            users: data.users,
            email: data.email,
            dolarValue: data.dolarValue,
            accountantName: data.accountantName,
            accountingStudy: data.accountantStudy,
            accountantPhone: data.accountantPhone,
            accountantContact: data.accountantContact,
            accountantEmail: data.accountantEmail,
            otherInformation: data.otherInformation,
            accountConfigurationId: data.accountConfigurationId,
            orderConfigurationId: data.orderConfigurationId,
            certificateList: data.certificates,
            reasonForUnsubscribeId: data.reasonForUnsubscribeId,
            reasonForUnsubscribeName: data.reasonForUnsubscribeName,
            unsubscribeDate: data.unsubscribeDate,
            accountPlanId: data.accountPlanId,
            accountPlanName: data.accountPlan,
            id: data.id,
            freeAccount: data.freeAccount,
          });
        }
      })
      .catch((err) => {
        toggleMessage(
          "error",
          err["message"] ?? "Ocurrió un error al obtener los datos de la cuenta"
        );
        history.goBack();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function saveAccount(values: AccountById) {
    setIsLoading(true);
    if (values.certificateList.some((item) => (item.certificateExpiredDay == undefined || item.certificateExpiredDay == null) && !item.whitoutCertificateAFIP)) {
      toggleMessage(
        "error",
        "Cargue Fecha Vencimiento del Certificado"
      );
      setIsLoading(false);
      return
    }
    try {
      let list: {
        id: number;
        whitoutCertificateAFIP: boolean;
        changeCertificate: boolean;
        certificate?: File;
        contentLenght?: number;
        cUIT: string;
        apiKey?: string;
        certificateExpiredDay?: Date;
        deleted?: boolean;
        afipSalePoint?: string[];
        businessName?: string;
      }[] = [];
      if (!values.whitoutAFIPCertificate) {
        values.certificateList.forEach(async (item) => {
          list.push({
            id: item.id,
            whitoutCertificateAFIP: item.whitoutCertificateAFIP,
            changeCertificate: item.changeCertificate,
            cUIT: item.cUIT,
            apiKey: item.apiKey,
            certificateExpiredDay: item.certificateExpiredDay,
            certificate: item.certificate,
          });
        });
      } else {
        values.certificateList.forEach((item) => {
          list.push({
            id: item.id,
            whitoutCertificateAFIP: item.whitoutCertificateAFIP,
            changeCertificate: item.changeCertificate,
            cUIT: item.cUIT,
          });
        });
      }
      let body: CreateAccount = {
        ...values,
        accountType: values.accountTypeName,
        certificateList: list,
      };

      accountService
        .editAccount(body, +id)
        .then((res) => {
          if (res.result) {
            toggleMessage("success", "Cuenta Editada exitosamente");
            history.push("/cuenta/lista");
          }
        })
        .catch((err) => toggleMessage("error", err["message"]));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik
            initialValues={initialValuesForm}
            validateOnChange={false}
            validationSchema={filterSchema}
            onSubmit={saveAccount}
          >
            {({
              values,
              errors,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <CreateOrEditAccount
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  id={id}
                />
                <Grid>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className="c-border-botom-green-ligth c-mt-20"
                    ></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
