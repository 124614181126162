import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
} from "@material-ui/core";
import { MissingSalesDTO } from "../../../../api/services/methods/methods.types";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import {  toggleMessage } from "../../../../utils/events";
import { GetMissingSales } from "../../../../api/services/methods";
import { useHistory } from "react-router-dom";

export default function MissingSales() {
    const history = useHistory();
    const [valueSearch, setValueSearch] = useState("");
    const [dataTable, setDataTable] = useState<MissingSalesDTO[]>([]);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [propsTable, setPropsTable] = useState<MissingSalesDTO[]>([]);

    useEffect(() => {
        obtainMissingSales();
    }, []);

    const obtainMissingSales = async () => {
        try {
            setIsLoadingState(true);
            let { result, data } = await GetMissingSales();
            if (result) {
                if (data?.length === 0 || !data) {
                    toggleMessage("success", "No se encontraron ventas faltantes.");
                } else {
                    setDataTable(data);
                    setPropsTable(data);
                }
            } else {
                toggleMessage("error", "Ocurrió un error al obtener las ventas faltantes.");
            }
        } catch (error) {
            toggleMessage("error", error);
            history.push("/metodo");
        } finally {
            setIsLoadingState(false);
        }
    };

    const handleChangeValueSearch = (e: { target: { value: string } }) => {
        setValueSearch(e.target.value.replace(/[^A-Za-z0-9áéíóúüÜÁÉÍÓÚñÑ .-/-]/g, ""));
    };

    const onClickSearch = () => {
        if (valueSearch !== "") {
            setDataTable(
                propsTable.filter((item: MissingSalesDTO) => {
                    return (
                        item.date.toLowerCase().includes(valueSearch.toLowerCase()) ||
                        item.voucher.toLowerCase().includes(valueSearch.toLowerCase()) ||
                        item.pointSale.toLowerCase().includes(valueSearch.toLowerCase()) ||
                        item.numberInvoice.toLowerCase().includes(valueSearch.toLowerCase()) ||
                        item.accountName.toLowerCase().includes(valueSearch.toLowerCase()) ||
                        item.cuit.toLowerCase().includes(valueSearch.toLowerCase())
                    );
                })
            );
        } else {
            setDataTable(propsTable);
        }
    };

    return (
        <div className="c-mt-60 c-ml-100">
            <h2>Validación de facturas salteadas</h2>
            <Grid container spacing={1} justifyContent="center" style={{ marginTop: "-35px" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <TextField
                        size="small"
                        id="filled-search"
                        variant="outlined"
                        className="c-mt-10 c-text-center"
                        disabled={isLoadingState}
                        value={valueSearch}
                        onChange={handleChangeValueSearch}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        className="c-mt-10 c-ml-10 c-cursor-pointer"
                        startIcon={<SearchIcon />}
                        disabled={isLoadingState}
                        onClick={onClickSearch}
                    >
                        Buscar
                    </Button>
                </div>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className="c-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="c-text-center">Fecha</TableCell>
                                    <TableCell className="c-text-center">Comprobante</TableCell>
                                    <TableCell className="c-text-center">Punto de Venta</TableCell>
                                    <TableCell className="c-text-center">Número Factura</TableCell>
                                    <TableCell className="c-text-center">Cuenta</TableCell>
                                    <TableCell className="c-text-center">CUIT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingState ? (
                                    <TableRow>
                                        <TableCell colSpan={6} className="c-text-center">
                                            Cargando...
                                        </TableCell>
                                    </TableRow>
                                ) : dataTable.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} className="c-text-center">
                                            No hay resultados
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    dataTable.map((item: MissingSalesDTO) => (
                                        <TableRow key={item.numberInvoice}>
                                            <TableCell className="c-rows-elipsis">{item.date}</TableCell>
                                            <TableCell className="c-rows-elipsis">{item.voucher}</TableCell>
                                            <TableCell className="c-mwp-10">{item.pointSale}</TableCell>
                                            <TableCell className="c-mwp-10">
                                                <div style={{ color: item.draw ? "red" : "inherit" }}>
                                                    {item.draw ? `${item.numberInvoice} - (Borrador)` : item.numberInvoice}
                                                </div>
                                            </TableCell>
                                            <TableCell className="c-mwp-10">{item.accountName}</TableCell>
                                            <TableCell className="c-mwp-10">{item.cuit}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}
