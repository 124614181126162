import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { DuplicateAndReduceLogo, ForwardByState, GenerateHashAllUsers, GetMissingSales, UploadAccountPlan } from "../../../api/services/methods";
import { StateEmailForwardModel } from "../../../api/services/methods/methods.types";


export default function Methods() {
    useEffect(() => {

    }, []);

    const duplicateAndReduceLogo = async () => {
        setIsLoading(true);
        try {
            let { result } = await DuplicateAndReduceLogo();
            if (result === 200) {
                toggleMessage("success", "Logos duplicados y reducidos correctamente.");
            } else {
                toggleMessage("error", "Error al duplicar y reducir Logos.");
            }
        } catch (error) {
            toggleMessage("error", error);
        }
        setIsLoading(false);
    };

    const generateHashAllUsers = async () => {
        setIsLoading(true);
        try {
            let { result } = await GenerateHashAllUsers();
            if (result === 200) {
                toggleMessage("success", "Hash en Usuarios creados correctamente.");
            } else {
                toggleMessage("error", "Error al generar Hash en Usuarios.");
            }
        } catch (error) {
            toggleMessage("error", error);
        }
        setIsLoading(false);
    };

    const forwardByState = async () => {
        setIsLoading(true);
        try {
            const emailState: StateEmailForwardModel = {
                StateEmail: 0,
            };
            let { data } = await ForwardByState(emailState);
            if (data) {
                toggleMessage("success", "Los Email Re-Enviados Fueron " + data.toString());
            } else {
                toggleMessage("error", "Error al reenviar Emails.");
            }
        } catch (error) {
            toggleMessage("error", error);
        }
        setIsLoading(false);
    }

    const uploadAccountPlan = async () => {
        setIsLoading(true);
        try {
            let { data } = await UploadAccountPlan();
            if (data) {
                toggleMessage("success", data);
            } else {
                toggleMessage("error", "Error al cargar Plan de Cuentas en todas las Cuentas.");
            }
        } catch (error) {
            toggleMessage("error", error);
        }
        setIsLoading(false);
    }

    return (
        <div className="c-mt-40 c-ml-100">
            <Grid container direction="column" alignItems="flex-start" style={{ marginTop: "4%" }} >
                <a href={"/metodo/corregirProductosRelacion"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Relación Producto/Lista de Precios
                    </Button>
                </a>

                <a href={"/metodo/VentasFaltantes"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Validar Facturas en Base Datos
                    </Button>
                </a>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        uploadAccountPlan();
                    }}
                >
                    Cargar Plan de Cuentas para todas las Cuentas
                </Button>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        forwardByState();
                    }}
                >
                    Enviar Emails con estado 4(Error)
                </Button>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {

                    }}
                    disabled
                >
                    Ajustar tamaño de imagenes de productos a 200px para reporte de Catalogo
                </Button>

                <a href={"/metodo/saldo"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Saldos Desacomodados
                    </Button>
                </a>
                <a href={"/metodo/stock"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Stocks Desacomodados
                    </Button>
                </a>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {

                    }}
                    disabled
                >
                    Ventas Mal Guardadas
                </Button>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {

                    }}
                    disabled
                >
                    Enviar Facturas a Elastic Search
                </Button>

                <a href={"/metodo/asignarFotosMasivamente"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Asignar fotos masivamente
                    </Button>
                </a>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {

                    }}
                    disabled
                >
                    Limpiar Settings
                </Button>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        generateHashAllUsers();
                    }}
                >
                    Generar Hash en Usuarios
                </Button>

                <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        duplicateAndReduceLogo();
                    }}
                >
                    Duplicar y Reducir tamaño Logos
                </Button>
                <a href={"/metodo/ordenCompraStock"}>
                    <Button variant="contained" size="small" className="btn-methods c-cursor-pointer c-mt-20 c-ml-5">
                        Generar Orden de Compra por Stock
                    </Button>
                </a>
            </Grid>
        </div>
    );
}