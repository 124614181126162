import { API, errorHandling } from "../index";
import { DefaultResponse } from "../SSOClient";
import { MissingSalesDTO, PersonDisorderedBalances, PersonDisorderedStocks, StateEmailForwardModel } from "./methods.types";

const BASE_URL: string = "/api/";

// Saldos Desacomodados
export async function ObtainDisorderedBalances(id: number): Promise<DefaultResponse<PersonDisorderedBalances[]>> {
  return API.get(BASE_URL + "customers/admin/person/disordered-balances?accountId=" + id).then(({ data }) => {
    return { result: true, data };
  }).catch(errorHandling);
}

export async function UpdateBalance(personaId: number, accountIdToUpdate: number) {
  return API.patch(BASE_URL + "customers/admin/person/balance?personId=" + personaId + "&accountIdToUpdate=" + accountIdToUpdate).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

// Stocks Desacomodados
export async function ObtainDisorderedStocks(id: number): Promise<DefaultResponse<PersonDisorderedStocks[]>> {
  return API.get(BASE_URL + "products/admin/disordered-stock?accountId=" + id).then(({ data }) => {
    return { result: true, data };
  }).catch(errorHandling);
}

export async function UpdateStock(productId: number, accountIdToUpdate: number) {
  return API.patch(BASE_URL + "products/admin/order-stock?productId=" + productId + "&accountIdToUpdate=" + accountIdToUpdate).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}


//Asignar Fotos Masivamente
export async function ImageProducts(accountId: number) {
  return API.get(BASE_URL + "accounts/" + accountId + "/process-image-products").then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  }).catch(errorHandling);
}


// Duplicar y Reducir logos
export async function DuplicateAndReduceLogo() {
  return API.put(BASE_URL + "account-certificate/admin/duplicate-logo").then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrido un error.",
      };
    }
  }).catch(errorHandling);
}


//Generar Hash en Usuarios
export async function GenerateHashAllUsers() {
  return API.put(BASE_URL + "nps").then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrido un error.",
      };
    }
  }).catch(errorHandling);
}

//Enviar Emails con estado 4(Error)
export async function ForwardByState(Emails: StateEmailForwardModel) {
  return API.post(BASE_URL + "emails/forward-by-state", Emails).then((res) => {
    if (res.data) {
      return {
        result: true,
        data: res.data,
      };
    } else {
      return {
        result: false,
        message: res.data["message"] ?? "Ocurrió un error.",
      };
    }
  });
}

//Corregir Productos No Relacionados Con Lista De Precios
export async function CorrectProductsNotRelatedToListPrice(accountId: number) {
  return API.post(BASE_URL + "method/products-price-lists-relation?accountId=" + accountId).then((res) => {
    if (res.data) {
      return {
        result: true,
        data: res.data,
      };
    } else {
      return {
        result: false,
        data: res.data["message"] ?? "Ocurrió un error.",
      };
    }
  });
}

//Obtener Ventas Faltantes
export async function GetMissingSales() : Promise<DefaultResponse<MissingSalesDTO[]>>{
  return API.get(BASE_URL + "method/missing-sales").then((res) => {
    if (res.data) {
      return {
        result: true,
        data: res.data,
      };
    } else {
      return {
        result: false,
        data: res.data,
      };
    }
  });
}

// 
export async function UploadAccountPlan() : Promise<DefaultResponse<string>> {
  return API.post(BASE_URL + "method/upload-account-plan").then((res) => {
    if (res.data) {
      return {
        result: true,
        data: res.data,
      };
    } else {
      return {
        result: false,
        message: res.data["message"] ?? "Ocurrió un error.",
      };
    }
  });
}

//Generar Orden de Compra por Stock
export async function GeneratePurchaseOrderStock(accountId: number) {
  return API.get(BASE_URL + "method/" + accountId + "/purchase-order-stock").then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        message: res.data,
      };
    } else {
      return {
        result: res.status,
        data: null,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  }).catch(errorHandling);
}
