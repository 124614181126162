import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as accountService from "../../../../api/services/Account";
import { GeneratePurchaseOrderStock, ImageProducts } from "../../../../api/services/methods";

type IList = {
    text: string;
    value: number;
};


export default function PurchaseOrderStock() {
    const [accountId, setAccountId] = useState<number>(0);
    const [list, setList] = useState<IList[]>([]);

    useEffect(() => {
        getAccountList();
    }, []);

    const getAccountList = async () => {
        let list: IList[] = [];
        try {
            let { data, message } = await accountService.accountDataList({ orderBy: "+ID" });
            if (data) {
                data["results"].map((item: any) => {
                    list.push({ text: item["name"], value: item["id"] });
                });
            } else {
                toggleMessage("error", message);
            }
        } catch {
            toggleMessage(
                "error",
                "Ocurrió un error inesperado, por favor, inténtelo más tarde"
            );
        }
        setList(list);
    };

    const postGeneratePurchaseOrderStock = async (id: number) => {
        setIsLoading(true);
        if (id > 0 && id !== null) {
            try {
                let { result, message } = await GeneratePurchaseOrderStock(id);
                if (result === 200) {
                    toggleMessage("success", message);
                } else {
                    toggleMessage("error", message);
                }
            } catch (error) {
                toggleMessage("error", error);
            }
        } else {
            toggleMessage("error", "Debe seleccionar una cuenta");
        }
        setIsLoading(false);
    };

    return (
        <div className="c-mt-40 c-ml-100">
            <Grid container direction="column" alignItems="flex-start" style={{ marginTop: "4%" }} >
                <h2>Generar Orden de Compra por Stock</h2>
                <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <CCustomSelectOrSearch
                        disabled={false}
                        name="AssignPhotosMassively"
                        placeHolder="Cuenta"
                        defaultValue={accountId}
                        onChange={(e) => {
                            setAccountId(e ? e.value : null);
                        }}
                        value={list.find(
                            (x: any) => x.value === accountId
                        )}
                        dataList={list}
                    />
                </div>

                <Button variant="contained" size="small" className="c-btn-green c-cursor-pointer c-mt-20 c-ml-5"
                    onClick={() => {
                        postGeneratePurchaseOrderStock(accountId);
                    }}
                >
                    Ejecutar Proceso
                </Button>
            </Grid>
        </div>
    );
}